/*eslint-disable*/

import {
  Flex,
  Link,
  List,
  ListItem,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

export default function Footer() {
  const textColor = useColorModeValue('gray.400', 'white');
  return (
    <Flex
      zIndex='3'
      flexDirection={{
        base: 'column',
        xl: 'row',
      }}
      alignItems={{
        base: 'center',
        xl: 'start',
      }}
      justifyContent='space-between'
      px={{ base: '30px', md: '50px' }}
      pb='30px'
    >
      <Text
        color={textColor}
        textAlign={{
          base: 'center',
          xl: 'start',
        }}
        mb={{ base: '20px', xl: '0px' }}
      >
        {' '}
        &copy; {new Date().getFullYear()}
        <Text as='span' fontWeight='500' ms='4px'>
          All Rights Reserved.
          <Link
            mx='3px'
            color={textColor}
            href='https://www.tradeflow.finance'
            target='_blank'
            fontWeight='700'
          >
            Tradeflow Technologies Inc.
          </Link>
        </Text>
      </Text>
      <List display='flex'>
        <List display='flex'>
          <ListItem
            me={{
              base: '20px',
              md: '44px',
            }}
          >
            <Link
              fontWeight='500'
              target='_blank'
              color={textColor}
              href='/terms.pdf'
            >
              Privacy policy
            </Link>
          </ListItem>
        </List>
      </List>
    </Flex>
  );
}
