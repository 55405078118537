/**
 * Symplifi   API
 * Symplifi backend API description
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface UploadFileRequest {
  type: UploadFileRequest.TypeEnum | null;
  directPaymentId?: string | null;
  paymentRequestId?: string | null;
  invoiceId?: string | null;
  productId?: string | null;
  userId?: string | null;
  companyId?: string | null;
}

export namespace UploadFileRequest {
  export type TypeEnum =
    | 'PURCHASE_ORDER'
    | 'INVOICE'
    | 'PROOF_OF_USER_PAYMENT'
    | 'PROOF_OF_PLATFORM_PAYMENT'
    | 'KYC_DOCUMENT'
    | 'BANK_STATEMENT'
    | 'PROCESSED_BANK_STATEMENT'
    | 'PROOF_OF_USER_SUBSCRIPTION_PAYMENT'
    | 'PRODUCT';
  export const TypeEnum = {
    PurchaseOrder: 'PURCHASE_ORDER' as TypeEnum,
    Invoice: 'INVOICE' as TypeEnum,
    ProofOfUserPayment: 'PROOF_OF_USER_PAYMENT' as TypeEnum,
    ProofOfPlatformPayment: 'PROOF_OF_PLATFORM_PAYMENT' as TypeEnum,
    KycDocument: 'KYC_DOCUMENT' as TypeEnum,
    BankStatement: 'BANK_STATEMENT' as TypeEnum,
    ProcessedBankStatement: 'PROCESSED_BANK_STATEMENT' as TypeEnum,
    ProofOfUserSubscriptionPayment:
      'PROOF_OF_USER_SUBSCRIPTION_PAYMENT' as TypeEnum,
    Product: 'PRODUCT' as TypeEnum,
  };
}
