import {
  Button,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  Text,
  Box,
  useColorModeValue,
  Flex,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { getCompany, updateCompany } from '../../../services/company';
import { CheckIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';

export default function HelpCenter() {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (isLoading) {
      return;
    }
  }, [isLoading, isAuthenticated]);

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Card
        w={{ base: '100%', md: '50%' }}
        mb={{ base: '0px', '2xl': '20px' }}
        gridArea={{ base: '2 / 1 / 3 / 2', lg: '1 / 2 / 2 / 3' }}
        minH='150px'
        pe='20px'
      >
        <Text fontSize='xl' fontWeight='bold' mb={4}>
          Help Center
        </Text>
        <Text>
          For customer support contact us at{' '}
          <a href='mailto:hello@tradeflow.finance'>hello@tradeflow.finance</a>
        </Text>
      </Card>
    </Box>
  );
}
