import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { createInvoice, getAllUnprocessedInvoices } from 'services/invoices';

export const useGetPendingInvoices = () => {
  const [pendingInvoices, setPendingInvoices] = useState<any[]>([]);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { getAccessTokenSilently } = useAuth0();

  // Fetch pending invoices from the API
  const fetchPendingInvoices = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      setAccessToken(accessToken);
      const data = await getAllUnprocessedInvoices(accessToken);
      // console.log('response:', response);

      setPendingInvoices(data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setError('An error occurred while fetching pending invoices');
      setIsLoading(false);
    }
  };

  // Call fetchPendingInvoices when the component mounts
  useEffect(() => {
    console.log('fetching pending invoices.');
    fetchPendingInvoices();
  }, []);

  return {
    accessToken,
    reload: fetchPendingInvoices,
    pendingInvoices,
    isLoading,
    error,
  };
};

export const useHandleSaveInvoice = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean | null>(null);

  const save = async (selectedInvoice: any, rows: any) => {
    try {
      setIsSaving(true);
      setError(null);
      const accessToken = await getAccessTokenSilently();
      await createInvoice(accessToken, selectedInvoice, rows);
      setSuccess(true);
    } catch (error) {
      console.error('Error saving changes:', error);
      setError('An error occurred while saving the invoice');
    } finally {
      setIsSaving(false);
    }
  };

  const reset = () => {
    setIsSaving(false);
    setError(null);
    setSuccess(null);
  };

  return {
    isSaving,
    reset,
    success,
    error,
    save,
  };
};
