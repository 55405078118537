import { UploadFileRequest } from '../../generated-client/model/upload-file-request';
import { UploadFileResponse } from '../../generated-client/model/upload-file-response';
import axios from 'axios';
import { saveAs } from 'file-saver';

export const uploadFile = async (
  accessToken: string,
  uploadFileRequest: UploadFileRequest,
  file: File
): Promise<UploadFileResponse> => {
  // Create a new FormData object
  const formData = new FormData();

  // Append the file and the uploadFileRequest object to the FormData object
  formData.append('file', file);
  formData.append('body', JSON.stringify(uploadFileRequest));

  try {
    // Make a POST request to the /file/upload endpoint with the FormData object
    const response = await axios.post(
      process.env.REACT_APP_BACKEND_URL + '/file/upload',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    // Return the response data
    return response.data;
  } catch (error) {
    // Handle any errors that occur during the request
    console.error('Error uploading file:', error);
    throw error;
  }
};

export const uploadFileAnon = async (
  uploadFileRequest: UploadFileRequest,
  file: File
): Promise<UploadFileResponse> => {
  // Create a new FormData object
  const formData = new FormData();
  // Append the file and the uploadFileRequest object to the FormData object
  formData.append('file', file);
  formData.append('body', JSON.stringify(uploadFileRequest));

  try {
    // Make a POST request to the /file/upload endpoint with the FormData object
    const response = await axios.post(
      process.env.REACT_APP_BACKEND_URL + '/file/anonUpload',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    // Return the response data
    return response.data;
  } catch (error) {
    // Handle any errors that occur during the request
    console.error('Error uploading file:', error);
    throw error;
  }
};

export const downloadFile = async (fileKey: string, accessToken: string) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_BACKEND_URL + '/file/download/' + fileKey,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const blob = await response.blob();

    const contentDisposition = response.headers.get('Content-Disposition');

    const filename = contentDisposition
      ? contentDisposition.split('filename=')[1]
      : 'file.pdf';
    saveAs(blob, filename);
  } catch (error) {
    console.error('There has been a problem with your fetch operation:', error);
  }
};

export const getFileLink = async (fileKey: string, accessToken: string) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_BACKEND_URL + '/file/view/' + fileKey,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    // console.log('response:', response);
    const data = await response.text();
    // console.log('data', data);

    return data;
  } catch (error) {
    console.error('There has been a problem with your fetch operation:', error);
  }
};
