import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Flex,
  FormControl,
  Input,
  Select,
  HStack,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { CloseIcon } from "@chakra-ui/icons";
import { CreateProductRequest } from "generated-client/model/create-product-request";
import { attachProductImage, createProduct } from "services/product";
import { uploadFile } from "services/file";

interface ProductModalProps {
  isOpen: boolean;
  onClose: () => void;
  supplierId: string;
  updateRefresh: (value: boolean) => void;
}

const ProductModal: React.FC<ProductModalProps> = ({
  onClose,
  isOpen,
  supplierId,
  updateRefresh,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const fileInputRefs = useRef<HTMLInputElement[]>([]);

  const [productPayloads, setProductPayloads] = useState<
    Omit<CreateProductRequest, "supplierId">[]
  >([
    {
      name: "",
      price: 0,
      stockAvailability: "IN_STOCK",
    },
  ]);
  const [error, setError] = useState(null);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [files, setFiles] = useState<(File | null)[]>([null]);

  const Options = ["IN_STOCK", "OUT_OF_STOCK"];

  const handleValueChange = (
    index: number,
    key: keyof CreateProductRequest,
    value: string
  ) => {
    const updatedPayloads = [...productPayloads];
    (updatedPayloads[index] as any)[key] = value;
    setProductPayloads(updatedPayloads);
  };

  const handleAddField = () => {
    setProductPayloads([
      ...productPayloads,
      {
        name: "",
        stockAvailability: "IN_STOCK",
      },
    ]);
  };

  const handleRemoveField = (indexToRemove: number) => {
    if (productPayloads.length > 1) {
      setProductPayloads((prevPayloads) =>
        prevPayloads.filter((_, index) => index !== indexToRemove)
      );
    }
  };

  const handleSubmit = async () => {
    setError("");
    const invalidPayload = productPayloads.some(
      (payload) => !payload.name.trim()
    );
    if (invalidPayload) {
      setError("Name can not be empty");
      return;
    }

    setIsSubmitLoading(true);

    const accessToken = await getAccessTokenSilently();

    try {
      await Promise.all(
        productPayloads.map(async (payload, index) => {
          const response = await createProduct(accessToken, {
            ...payload,
            supplierId,
          });

          if (files[index]) {
            const fileUploaded = await uploadFile(
              accessToken,
              {
                productId: response.productId,
                type: "PRODUCT",
              },
              files[index]
            );

            await attachProductImage(accessToken, {
              id: response.productId,
              fileId: fileUploaded.id,
            });
          }
        })
      );
      onClose();
      setProductPayloads([
        {
          name: "",
          stockAvailability: "IN_STOCK",
        },
      ]);
      setFiles([null]);
      updateRefresh(true);
    } catch (error) {
      console.error("Error creating product:", error);
      setError(
        "An error occurred while creating the product. Please try again."
      );
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const handleFileChange = (index: number, event: any) => {
    const updatedFiles = [...files];
    updatedFiles[index] = event.target.files[0];
    setFiles(updatedFiles);
  };

  const triggerFileInput = (index: number) => {
    if (fileInputRefs.current[index]) {
      fileInputRefs.current[index].click();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex justifyContent="space-between" alignItems="center">
            <Text>Add Product</Text>
            <CloseIcon onClick={onClose} />
          </Flex>
        </ModalHeader>
        <ModalBody>
          <FormControl>
            {productPayloads.map((payload, index) => (
              <FormControl key={index}>
                <Flex gap="2" alignItems="center" mb={8}>
                  <Input
                    placeholder="Product name"
                    value={payload.name}
                    onChange={(e) =>
                      handleValueChange(index, "name", e.target.value)
                    }
                  />

                  <Input
                    placeholder="Product price"
                    value={payload.name}
                    onChange={(e) =>
                      handleValueChange(index, "price", e.target.value)
                    }
                  />
                  <Select
                    value={payload.stockAvailability}
                    onChange={(e) =>
                      handleValueChange(
                        index,
                        "stockAvailability",
                        e.target.value
                      )
                    }
                  >
                    {Options.map((val, index) => (
                      <option key={index} value={val}>
                        {val.replace(/_/g, " ")}
                      </option>
                    ))}
                  </Select>

                  <input
                    type="file"
                    style={{ display: "none" }}
                    ref={(el) => (fileInputRefs.current[index] = el)}
                    onChange={(event) => handleFileChange(index, event)}
                    accept="image/*"
                  />
                  <Button
                    onClick={() => triggerFileInput(index)}
                    hidden={isSubmitLoading}
                    mr="3px"
                    colorScheme="blue"
                    width={"50%"}
                  >
                    {files[index] ? "✅" : ""} photo
                  </Button>

                  <HStack>
                    {index === productPayloads.length - 1 && (
                      <Button
                        onClick={handleAddField}
                        colorScheme="blackAlpha"
                        h={4}
                      >
                        +
                      </Button>
                    )}
                  </HStack>

                  <HStack>
                    {index > 0 && (
                      <Button
                        onClick={() => handleRemoveField(index)}
                        colorScheme="blackAlpha"
                        ml={4}
                        h={4}
                      >
                        X
                      </Button>
                    )}
                  </HStack>
                </Flex>
              </FormControl>
            ))}
          </FormControl>

          <Flex justifyContent="flex-start" mt={4}>
            {error && (
              <Text color="red.500" mt={2}>
                {error}
              </Text>
            )}
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Flex width="100%" justifyContent="center">
            <Button
              colorScheme="blue"
              onClick={handleSubmit}
              isLoading={isSubmitLoading}
              disabled={isSubmitLoading}
            >
              Add
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ProductModal;
