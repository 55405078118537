//jmport { useQuery } from 'react-query';
//import { getPurchaseOrderById } from '../api';

import { useCallback, useEffect, useState } from 'react';
import { validatePurchaseOrder } from 'services/payment-request';

const useValidatePurchaseOrder = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [id, setId] = useState<string>();
  const [data, setData] = useState<Boolean | null>(null);

  const getData = useCallback(async () => {
    console.log('here in callback 1');
    if (id) {
      console.log('here3');
      setIsLoading(true);
      setError(null);
      try {
        const data = await validatePurchaseOrder(id);
        console.log('got success', data);
        setData(data);
      } catch (error: any) {
        console.log('got an error');
        setError(error);
      } finally {
        setIsLoading(false);
        console.log('after finally');
      }
    }
  }, [id]);

  useEffect(() => {
    getData();
  }, [getData]);

  const setPurchaseOrder = async (id: string) => {};

  console.log('id is', id);

  return { purchaseOrder: data, setPurchaseOrderId: setId, isLoading, error };
};

export default useValidatePurchaseOrder;
