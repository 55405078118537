/**
 * Symplifi   API
 * Symplifi backend API description 
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface GetAllPaymentRequestsRequest { 
    country?: GetAllPaymentRequestsRequest.CountryEnum;
    companyNameSearch?: string;
    amountFrom?: number;
    amountTo?: number;
    skip?: number;
    take?: number;
}
export namespace GetAllPaymentRequestsRequest {
    export type CountryEnum = 'Rwanda' | 'Kenya' | 'SouthAfrica';
    export const CountryEnum = {
        Rwanda: 'Rwanda' as CountryEnum,
        Kenya: 'Kenya' as CountryEnum,
        SouthAfrica: 'SouthAfrica' as CountryEnum
    };
}


