import React, { useEffect } from 'react';
import {
  Box,
  Flex,
  Heading,
  Input,
  Button,
  useToast,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import useValidatePurchaseOrder from './uploadHooks';
import { uploadFileAnon } from 'services/file';
import { attachInvoiceToPaymentRequest } from 'services/payment-request';

const UploadPage = () => {
  const [isOpen, setIsOpen] = React.useState(true);
  const [id, setId] = React.useState<string>();
  const [type, setType] = React.useState<string>();
  const [userId, setUserId] = React.useState<string>();
  const [companyId, setCompanyId] = React.useState<string>();
  const validatePurchaseOrder = useValidatePurchaseOrder();
  const toast = useToast();
  const location = useLocation();
  // const userId = '7db15855-49c3-49d8-a196-f46c57dccb55';
  // const companyId = '0d38f8c4-7bc0-469b-94cf-6fce2c071501';

  useEffect(() => {
    if (location.search) {
      const queryParams = new URLSearchParams(location.search);
      const purchaseOrderId = queryParams.get('id');
      setType(queryParams.get('type'));
      setUserId(queryParams.get('userId'));
      setCompanyId(queryParams.get('companyId'));

      validatePurchaseOrder.setPurchaseOrderId(purchaseOrderId ?? '');
      setId(purchaseOrderId);
    }
  }, [location.search, validatePurchaseOrder]);

  // const queryParams = new URLSearchParams(location.search);
  // const type = queryParams.get('type');
  const title = type == 'invoice' ? 'Invoice' : 'Receipt';

  const handleFileUpload = async (event: any) => {
    const file = event.target.files[0];
    const fileUpload = await uploadFileAnon(
      {
        type: type === 'invoice' ? 'INVOICE' : 'PROOF_OF_USER_PAYMENT',
        paymentRequestId: id,
        userId: userId,
        companyId: companyId,
      },
      file
    );

    const attachRequest = await attachInvoiceToPaymentRequest(
      id,
      fileUpload.id,
      companyId
    );

    console.log('attach request', attachRequest);

    toast({
      title: 'File uploaded',
      description: `${file.name} has been uploaded successfully.`,
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
  };

  const onClose = () => setIsOpen(false);

  console.log('vpo', validatePurchaseOrder);

  if (!id) {
    return (
      <>
        <AlertDialog
          isOpen={isOpen}
          onClose={onClose}
          leastDestructiveRef={undefined}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                Parameters not loaded
              </AlertDialogHeader>

              <AlertDialogBody>
                The parameters haven't loaded yet. Please try again later.
              </AlertDialogBody>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    );
  }

  if (validatePurchaseOrder.purchaseOrder === false) {
    return (
      <>
        <AlertDialog
          isOpen={isOpen}
          onClose={() => {}}
          leastDestructiveRef={undefined}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                Purchase Order not found
              </AlertDialogHeader>

              <AlertDialogBody>
                The purchase order you are trying to upload a {title} for
                doesn't exist.
              </AlertDialogBody>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    );
  }

  if (validatePurchaseOrder.isLoading) {
    return (
      <Flex height='100vh' alignItems='center' justifyContent='center'>
        <Box
          borderWidth={1}
          px={4}
          py={6}
          borderRadius={8}
          boxShadow='lg'
          maxWidth='500px'
          width='100%'
        >
          <Heading mb={6}>Validating {title}</Heading>
        </Box>
      </Flex>
    );
  }

  return (
    <Flex height='100vh' alignItems='center' justifyContent='center'>
      <Box
        borderWidth={1}
        px={4}
        py={6}
        borderRadius={8}
        boxShadow='lg'
        maxWidth='500px'
        width='100%'
      >
        <Heading mb={6}>Upload {title}</Heading>
        <Input
          type='file'
          mb={4}
          onChange={handleFileUpload}
          accept='image/*,video/*,audio/*,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx'
        />
        <Button colorScheme='blue' width='100%'>
          Upload
        </Button>
      </Box>
    </Flex>
  );
};

export default UploadPage;
