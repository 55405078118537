import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Box,
  Button,
  Flex,
  Image,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Input,
  useColorModeValue,
  Text,
  InputLeftAddon,
  InputGroup,
} from '@chakra-ui/react';
import logoImage from '../../../assets/img/dashboards/tradeflowlogo.png';
import { useNavigate } from 'react-router-dom';
import 'react-phone-number-input/style.css';
import 'flag-icon-css/css/flag-icons.css';
import PhoneInput, { Country } from 'react-phone-number-input';
import './../../../assets/css/App.css';

const getCountryCodeFromBrowserLanguage = () => {
  const browserLanguage = navigator.language.toUpperCase();
  return browserLanguage.split('-')[1] || browserLanguage;
};

function SignIn() {
  const navigate = useNavigate();
  const { loginWithRedirect, isLoading, isAuthenticated } = useAuth0();
  const [authMethod, setAuthMethod] = useState('email');
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard/default');
    }
  }, [isLoading, isAuthenticated]);

  const handleLogin = () => {
    if (authMethod === 'phone') {
      loginWithRedirect({
        authorizationParams: {
          connection: 'sms',
          login_hint: inputValue,
        },
      });
    } else {
      loginWithRedirect({
        authorizationParams: {
          connection: 'email',
          login_hint: inputValue,
        },
      }).catch((error) => {
        console.log(error);
      });
    }
  };

  return (
    <Flex
      w='100vw'
      h='100vh'
      alignItems={{ base: 'center', md: 'center' }}
      justifyContent={{ base: 'center', md: 'left' }}
      flexDirection={{ base: 'column', md: 'row' }} // Responsive: column on base (small screens) and row on medium and up.
      bg='white'
    >
      {/* Left Side Content */}
      <Flex
        flexDirection='column'
        alignItems='left'
        justifyContent='left'
        width={{ base: '100%', md: '50%' }} // Responsive: 100% on base (small screens) and 50% on medium and up.
        p='10%'
      >
        <Image
          src={logoImage}
          //   h='40px'
          w='100%'
          maxW={{ base: '200px', md: '300px' }}
          my='32px'
          alt='Logo'
          mb='20px'
        />
        <Text fontSize='2xl' fontWeight='bold' mb='20px' textAlign='left'>
          The easy way to order and manage your wine, spirits and beverages
          inventory
        </Text>
        <Box ml='15px'>
          <ul>
            <li>
              <Text mb='10px'>Order from all your suppliers in minutes</Text>
            </li>
            <li>
              <Text mb='10px'>
                Data and insights to better manage inventory
              </Text>
            </li>
            <li>
              <Text mb='10px'>Get access to discount prices</Text>
            </li>
          </ul>
        </Box>
      </Flex>

      {/* Right Side Content */}
      <Flex
        flexDirection='column'
        alignItems='left'
        justifyContent='left'
        width={{ base: '100%', md: '50%' }} // Responsive: 100% on base (small screens) and 50% on medium and up.
        p='5%'
      >
        <Box
          bg='white'
          style={{ boxShadow: '0 3px 6px rgba(0, 0, 0, 0.2)' }}
          w={{ base: '100%', md: '400px' }}
          h='300px'
          borderRadius='md'
        >
          <Flex
            flexDirection='column'
            alignItems='center'
            width='100%'
            pt='10px'
          >
            <Text fontSize='2xl' mb='5px' textAlign='left'>
              <b>Sign In</b>
            </Text>
            <Text mb='20px' textAlign='left'>
              Select a method below to authenticate
            </Text>

            <Tabs
              variant='enclosed'
              colorScheme='blue'
              minW='250px'
              onChange={(index) =>
                setAuthMethod(index === 0 ? 'email' : 'phone')
              }
            >
              <TabList mb='1em'>
                <Tab borderRadius='0'>Email</Tab>
                {/* <Tab borderRadius='0'>Phone</Tab> */}
              </TabList>
              <TabPanels>
                <TabPanel p='0px'>
                  <Input
                    placeholder='Enter your email'
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    mb='20px'
                    borderRadius='0'
                    // borderColor="#D00E17"
                    h='40px'
                    w='100%'
                    minH='52px'
                  />
                </TabPanel>
                <TabPanel p='0px'>
                  <Flex align='center' w='100%'>
                    <PhoneInput
                      placeholder='Enter your phone number'
                      value={inputValue}
                      defaultCountry={
                        //getCountryCodeFromBrowserLanguage() as Country
                        'ZA'
                      }
                      onChange={setInputValue}
                      mb='20px'
                      borderRadius='0'
                      international
                      displayInitialValueAsLocalNumber
                      style={{
                        border: '1px solid #E2E8F0',
                        borderRadius: '0',
                        height: '52px',
                        width: '100%',
                        outline: 'none',
                        padding: '10px 8px 10px 10px',
                        marginBottom: '20px',
                      }}
                      className={'input-phone-number'}
                    />
                  </Flex>
                </TabPanel>
              </TabPanels>
            </Tabs>

            <Button
              colorScheme='blue'
              w='250px'
              minH='52px'
              borderRadius='0'
              onClick={handleLogin}
              bg='#635dff'
              variant='solid'
              mb='20px'
            >
              Continue
            </Button>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
}

export default SignIn;
