import { Box, Button, Text, FormControl } from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { DirectPaymentDto } from '../../../../generated-client/model/direct-payment-dto';
import { downloadFile } from 'services/file';
import { UploadFileRequest } from '../../../../generated-client/model/upload-file-request';
import TypeEnum = UploadFileRequest.TypeEnum;
import StatusEnum = DirectPaymentDto.StatusEnum;
import { formatCurrency } from '../../../../lib/utilities';

interface CardLayoutProps {
  directPayments: DirectPaymentDto[];
  accessToken: string;
  sendFile: (file: File, directPaymentId: string) => void;
  handleStatusChange: (directPaymentId: string, newStatus: string) => void;
  fetchData: () => void;
  errorMessages: { [requestId: string]: string };
}

export default function CardLayout({
  directPayments,
  accessToken,
  sendFile,
  handleStatusChange,
  errorMessages,
  fetchData,
}: CardLayoutProps) {
  const [loadingStates, setLoadingStates] = useState<{
    [key: string]: boolean;
  }>({});
  const [currentDirectPaymentId, setCurrentDirectPaymentId] = useState<
    string | null
  >(null);
  let fileInputRef = useRef(null);

  const setRowLoading = (id: string, isLoading: boolean) => {
    setLoadingStates((prev) => ({ ...prev, [id]: isLoading }));
  };

  const handleFileUpload = async (file: File, directPaymentId: string) => {
    setRowLoading(directPaymentId, true);
    await sendFile(file, directPaymentId);
    setRowLoading(directPaymentId, false);
    fetchData();
  };

  const handleFileChange = (event: any) => {
    if (!currentDirectPaymentId) return;
    const file = event.target.files[0];
    handleFileUpload(file, currentDirectPaymentId);
  };

  const triggerFileInput = (directPaymentId: string) => {
    setCurrentDirectPaymentId(directPaymentId);
    fileInputRef.current.click();
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      {directPayments?.map((payment) => (
        <Box
          key={payment.id}
          bg='white'
          p={5}
          shadow='md'
          borderWidth='1px'
          mb={4}
        >
          <Text fontSize='xl'>{payment.recipientName}</Text>

          {/* Info section */}
          <Box mb={4} fontSize='0.9em'>
            {payment.companyName && <Text>Sender: {payment.companyName}</Text>}
            <Text>Recipient: {payment.recipientName}</Text>
            <Text>Address: {payment.recipientAddress}</Text>
            <Text>Phone Number: {payment.recipientPhoneNumber}</Text>
            <Text>Account Number: {payment.recipientAccountNumber}</Text>
            <Text>Bank Swift code: {payment.recipientSwiftCode}</Text>
            <Text>Country: {payment.country}</Text>
            <Text>Amount: {'$ ' + payment.amount}</Text>
            <Text>
              Exchange Rate:{' '}
              {payment.amountToPayCurrency + ' ' + payment.exchangeRate}
            </Text>
            <Text>
              Amount to Pay:{' '}
              {payment.amountToPayCurrency + ' ' + payment.amountToPay}
            </Text>
            {payment.paymentRequestId && (
              <>
                <br />
                <Text>
                  <b>Flex pay</b>
                </Text>
                <Text>
                  Credit amount:{' '}
                  {payment.paymentRequestCreditAmountCurrency +
                    ' ' +
                    formatCurrency(payment.paymentRequestCreditAmount)}
                </Text>
                <Text>
                  Pay now:{' '}
                  {payment.paymentRequestAmountToPayNowCurrency +
                    ' ' +
                    formatCurrency(payment.paymentRequestAmountToPayNow)}
                </Text>
                <Text>
                  Due date:{' '}
                  {new Date(payment.paymentRequestDueDate).toLocaleDateString(
                    'en-US',
                    {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    }
                  )}
                </Text>
              </>
            )}
            <Text>Status: </Text>
            <select
              value={
                payment.status === 'PROOF_OF_USER_PAYMENT_SUBMITTED' ||
                payment.status === 'PROOF_OF_PLATFORM_PAYMENT_SUBMITTED'
                  ? 'PENDING'
                  : payment.status
              }
              onChange={(e) => handleStatusChange(payment.id, e.target.value)}
            >
              {Object.values(StatusEnum)
                .filter(
                  (s) =>
                    s !== 'PROOF_OF_USER_PAYMENT_SUBMITTED' &&
                    s !== 'PROOF_OF_PLATFORM_PAYMENT_SUBMITTED'
                )
                .map((status) => (
                  <option key={status} value={status}>
                    {status.replace('_', ' ')}
                  </option>
                ))}
            </select>
            <div style={{ minHeight: '20px' }}>
              {errorMessages[payment.id] && (
                <Text color='red.500'>{errorMessages[payment.id]}</Text>
              )}
            </div>
          </Box>

          {/* Files section */}
          <Box mb={4}>
            <Text mb={2}>Files:</Text>
            {payment.files?.map((file, index) => (
              <Button
                fontSize='sm'
                color='grey'
                key={index}
                onClick={() => downloadFile(file.key, accessToken)}
              >
                {file.type === 'PROOF_OF_PLATFORM_PAYMENT'
                  ? 'PROOF_OF_PAYMENT'
                  : file.type}
              </Button>
            ))}
          </Box>

          {/* Upload section */}
          <Text mb={2}>Actions:</Text>
          {!payment.files?.find(
            (f) => f.type === TypeEnum.ProofOfPlatformPayment
          ) && (
            <FormControl>
              <input
                type='file'
                style={{ display: 'none' }}
                ref={fileInputRef}
                onChange={handleFileChange}
                accept='image/*, .pdf, .docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .jpg, .png, .jpeg'
              />
              <Button
                onClick={() => triggerFileInput(payment.id)}
                isLoading={loadingStates[payment.id]}
              >
                <Text fontSize='sm' color='grey'>
                  Upload proof of payment
                </Text>
              </Button>
            </FormControl>
          )}
        </Box>
      ))}
    </Box>
  );
}
