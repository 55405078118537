import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  Text,
  ModalHeader,
  Flex,
  IconButton,
  VStack,
  Select,
  FormControl,
  Input,
  Button,
  Divider,
  Icon,
  InputGroup,
  InputLeftAddon,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CheckCircleIcon, CloseIcon } from '@chakra-ui/icons';
import { SourcingRequestProFormaInvoiceItemDto } from 'generated-client/model/sourcing-request-pro-forma-invoice-item-dto';
import { formatCurrency, roundNumber } from 'lib/utilities';
import { createProFormaInvoice } from 'services/sourcing';
import { SourcingRequestDto } from 'generated-client/model/sourcing-request-dto';
import { getExchangeRate } from 'services/finance';
import { ExchangeRateDto } from 'generated-client/model/exchange-rate-dto';

interface CreateProFormaInvoiceModalProps {
  isOpen: boolean;
  onClose: () => void;
  sourcingRequest: SourcingRequestDto;
  accessToken: string;
  fetchData?: any;
}

const CreateProFormaInvoiceModal: React.FC<CreateProFormaInvoiceModalProps> = ({
  onClose,
  isOpen,
  sourcingRequest,
  accessToken,
  fetchData,
}) => {
  const [supplierCountry, setSupplierCountry] = useState('');
  const [supplierName, setSupplierName] = useState('');
  const [supplierAddress, setSupplierAddress] = useState('');
  const [items, setItems] = useState<SourcingRequestProFormaInvoiceItemDto[]>(
    []
  );
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalAmountInLocalCurrency, setTotalAmountInLocalCurrency] =
    useState(0);
  const [
    formattedTotalAmountInLocalCurrency,
    setFormattedTotalAmountInLocalCurrency,
  ] = useState('');
  const [shippingEstimateAmount, setShippingEstimateAmount] =
    useState<number>();
  const [formattedShippingEstimateAmount, setFormattedShippingEstimateAmount] =
    useState('');
  const [arrivalDate, setArrivalDate] = useState('');
  const [error, setError] = useState('');
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [fxRateSupplier, setFxRateSupplier] = useState<number>(0);
  const [fxRateOther, setFxRateOther] = useState<number>(0);
  const [usdToRwf, setUsdToRwf] = useState<number>(0);
  const [requestSent, setRequestSent] = useState(false);

  // TODO: move to separate file
  const countryCurrencyMap = {} as any;
  countryCurrencyMap['United Arab Emirates'] = 'AED';
  countryCurrencyMap['China'] = 'CNY';

  const recalculateTotal = () => {
    let total = 0;
    items.map((i) => (total = total + i.total));

    total = roundNumber(total);

    setTotalAmount(total);
  };

  const handleTotalAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value.replace(/,/g, '');
    setTotalAmountInLocalCurrency(Number(rawValue));
    const formattedValue = formatCurrency(Number(rawValue));
    setFormattedTotalAmountInLocalCurrency(formattedValue);
  };

  const handleShippingEstimateChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const rawValue = e.target.value.replace(/,/g, '');
    setShippingEstimateAmount(Number(rawValue));
    const formattedValue = formatCurrency(Number(rawValue));
    setFormattedShippingEstimateAmount(formattedValue);
  };

  const handleItemNameChange = (index: number, value: string) => {
    const updatedItems = [...items];
    updatedItems[index]['name'] = value;
    setItems(updatedItems);
  };

  const handleItemPriceChange = (index: number, value: number) => {
    const updatedItems = [...items];
    updatedItems[index]['price'] = value;
    updatedItems[index]['total'] = roundNumber(
      updatedItems[index]['quantity'] * value
    );
    setItems(updatedItems);
    recalculateTotal();
  };

  const handleItemQuantityChange = (index: number, value: number) => {
    const updatedItems = [...items];
    updatedItems[index]['quantity'] = value;
    updatedItems[index]['total'] = roundNumber(
      updatedItems[index]['price'] * value
    );
    setItems(updatedItems);
    recalculateTotal();
  };

  const resetFormFields = (shouldResetItem?: boolean) => {
    setSupplierCountry('');
    setSupplierName('');
    setSupplierAddress('');

    setTotalAmount(0);
    setShippingEstimateAmount(0);
    setArrivalDate('');
    setError('');
    setIsSubmitLoading(false);
    setRequestSent(false);

    if (shouldResetItem) {
      setItems([]);
    }
  };

  async function fetchRates() {
    try {
      const fxRateSupplier = await getExchangeRate(
        accessToken,
        ExchangeRateDto.FromEnum.Usd,
        ExchangeRateDto.ToEnum.KesSupplier
      );
      const fxRateOther = await getExchangeRate(
        accessToken,
        ExchangeRateDto.FromEnum.Usd,
        ExchangeRateDto.ToEnum.KesOther
      );
      const fxRateRwf = await getExchangeRate(
        accessToken,
        ExchangeRateDto.FromEnum.Usd,
        ExchangeRateDto.ToEnum.Rwf
      );
      setFxRateOther(fxRateOther.exchangeRate.value);
      setFxRateSupplier(fxRateSupplier.exchangeRate.value);
      setUsdToRwf(fxRateRwf.exchangeRate.value);
    } catch (error) {
      console.error('Error fetching company data:', error);
    }
  }

  const handleClose = () => {
    onClose();
    resetFormFields(true);
    fetchData({
      skip: 0,
      take: 20,
    });
  };

  const handleAddNewInvoice = () => {
    resetFormFields();
    fetchData({
      skip: 0,
      take: 20,
    });
  };

  const isValidForm = (): boolean => {
    if (!supplierName) {
      setError('Supplier name can not be empty');
      setIsSubmitLoading(false);
      return false;
    }

    if (!supplierCountry) {
      setError('Supplier country can not be empty');
      setIsSubmitLoading(false);
      return false;
    }

    if (!supplierAddress) {
      setError('Supplier address can not be empty');
      setIsSubmitLoading(false);
      return false;
    }

    if (!shippingEstimateAmount) {
      setError('Shipping estimate can not be empty');
      setIsSubmitLoading(false);
      return false;
    }

    if (shippingEstimateAmount <= 0) {
      setError('Shipping estimate can not be zero');
      setIsSubmitLoading(false);
      return false;
    }

    if (!arrivalDate) {
      setError('Arrival date can not be empty');
      setIsSubmitLoading(false);
      return false;
    }

    // Validate the items
    for (const item of items) {
      console.log({ item });
      if (item.name.trim() === '' || item.quantity === 0 || item.price === 0) {
        setError(
          'All items must have a name, price and quantity greater than 0.'
        );
        setIsSubmitLoading(false);
        return false;
      }
    }

    return true;
  };

  const handleSubmit = async () => {
    setIsSubmitLoading(true);
    try {
      if (isValidForm()) {
        const resp = await createProFormaInvoice(accessToken, {
          items,
          supplierAddress,
          supplierName,
          totalAmount: totalAmount,
          totalAmountCurrency: countryCurrencyMap[supplierCountry],
          totalAmountLocal: totalAmountInLocalCurrency,
          totalAmountLocalCurrency:
            sourcingRequest?.company?.country === 'Kenya' ? 'KES' : 'RWF',
          shippingArrivalDate: new Date(arrivalDate) as unknown as string,
          shippingEstimateAmount,
          shippingEstimateAmountCurrency:
            sourcingRequest?.company?.country === 'Kenya' ? 'KES' : 'RWF',
          sourcingRequestId: sourcingRequest.id,
        });

        if (resp) {
          setRequestSent(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
    setIsSubmitLoading(false);
  };

  useEffect(() => {
    if (sourcingRequest?.items) {
      const newItems = sourcingRequest?.items?.map((item) => {
        return {
          name: item.name,
          quantity: Number(item.quantity),
          id: item.id,
          price: 0,
          total: 0,
        };
      });
      setItems(newItems);
    }
  }, [sourcingRequest?.items]);

  useEffect(() => {
    fetchRates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isOpen) {
      resetFormFields(true);
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size='lg'>
      <ModalOverlay />
      <ModalContent
        overflowY='auto'
        maxH='80vh'
        maxW={{ base: '90%', sm: '90%', md: '70%', lg: '50%', xl: '50%' }}
      >
        <ModalHeader>
          <Flex align='center'>
            <Text>Proforma Invoice</Text>
            <IconButton
              aria-label='Close'
              icon={<CloseIcon />}
              size='sm'
              ml='auto'
              onClick={onClose}
              color='gray.500'
              variant='ghost'
            />
          </Flex>
        </ModalHeader>
        <ModalBody>
          {!requestSent && (
            <VStack spacing={4} align='start'>
              <FormControl>
                <Text mb={2} fontSize='sm' color='gray.500'>
                  Supplier country
                </Text>
                <Select
                  value={supplierCountry}
                  placeholder='Select supplier country'
                  onChange={(e) => setSupplierCountry(e.target.value)}
                >
                  <option value='China'>China</option>
                  <option value='United Arab Emirates'>
                    United Arab Emirates
                  </option>
                </Select>
              </FormControl>
              <FormControl>
                <Text mb={2} fontSize='sm' color='gray.500'>
                  Supplier Name
                </Text>
                <Input
                  value={supplierName}
                  onChange={(e) => setSupplierName(e.target.value)}
                />
              </FormControl>
              <FormControl>
                <Text mb={2} fontSize='sm' color='gray.500'>
                  Supplier Address
                </Text>
                <Input
                  value={supplierAddress}
                  onChange={(e) => setSupplierAddress(e.target.value)}
                />
              </FormControl>
              <Text>Enter products</Text>
              {items.map((item, index) => {
                return (
                  <Flex gap={4} key={index} mb={4}>
                    <FormControl>
                      <Text mb={2} fontSize='sm' color='gray.500'>
                        Product
                      </Text>
                      <Input
                        value={item.name}
                        onChange={(e) =>
                          handleItemNameChange(index, e.target.value)
                        }
                      />
                    </FormControl>
                    <FormControl>
                      <Text mb={2} fontSize='sm' color='gray.500'>
                        Quantity
                      </Text>
                      <Input
                        placeholder='0'
                        value={item.quantity}
                        type='number'
                        disabled
                        onBlur={(e) =>
                          handleItemQuantityChange(
                            index,
                            e.target.value ? parseFloat(e.target.value) : 0
                          )
                        }
                      />
                    </FormControl>
                    <FormControl>
                      <Text mb={2} fontSize='sm' color='gray.500'>
                        Price
                      </Text>
                      <Input
                        placeholder='0'
                        onBlur={(e) =>
                          handleItemPriceChange(
                            index,
                            e.target.value ? parseFloat(e.target.value) : 0
                          )
                        }
                        type='number'
                      />
                    </FormControl>
                  </Flex>
                );
              })}

              <Text>
                Total amount: {formatCurrency(totalAmount)}{' '}
                {countryCurrencyMap[supplierCountry]}
              </Text>
              <Text>
                Amount in local currency:{' '}
                <InputGroup>
                  <InputLeftAddon children={'ZAR'} />
                  <Input
                    value={formattedTotalAmountInLocalCurrency}
                    onChange={handleTotalAmountChange}
                    type='text'
                  />
                </InputGroup>
              </Text>

              <Divider />

              <FormControl>
                <Text mb={2} fontSize='sm' color='gray.500'>
                  Shipping estimate
                </Text>
                <InputGroup>
                  <InputLeftAddon
                    children={
                      sourcingRequest?.company?.country === 'Kenya'
                        ? 'KES'
                        : 'RWF'
                    }
                  />
                  <Input
                    value={formattedShippingEstimateAmount}
                    onChange={handleShippingEstimateChange}
                    type='text'
                  />
                </InputGroup>
              </FormControl>

              <FormControl>
                <Text mb={2} fontSize='sm' color='gray.500'>
                  Arrival date
                </Text>
                <Input
                  value={arrivalDate}
                  onChange={(e) => setArrivalDate(e.target.value)}
                  type='date'
                />
              </FormControl>

              {error && (
                <Text color='red.500' mb={4}>
                  {error}
                </Text>
              )}
            </VStack>
          )}

          {requestSent && (
            <VStack spacing={4} align='center'>
              <Icon
                as={CheckCircleIcon}
                mt={'30px'}
                boxSize={8}
                color='green.500'
              />
              <Text textAlign='center'>Invoice generated</Text>

              <Flex mt={8} gap={4}>
                <Button
                  colorScheme='blue'
                  onClick={handleAddNewInvoice}
                  size='md'
                >
                  Add new invoice
                </Button>
                <Button colorScheme='green' onClick={handleClose} size='md'>
                  Done
                </Button>
              </Flex>
            </VStack>
          )}
        </ModalBody>
        <ModalFooter>
          {!requestSent && (
            <Button
              colorScheme='blue'
              onClick={handleSubmit}
              isLoading={isSubmitLoading}
            >
              Submit
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateProFormaInvoiceModal;
