import {
  RadioGroup,
  VStack,
  Box,
  Radio,
  Flex,
  Text,
  Divider,
} from '@chakra-ui/react';
import { formatCurrency } from 'lib/utilities';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface PaymentOptionsBlockProps {
  onChange: (value: string) => void;
  value: string;
  totalAmount: number;
  currency: string;
  creditLimit: number;
  radioGroupWidth: number;
  radioGroupRef: React.RefObject<HTMLDivElement>;
  shouldRenderFlexPay: boolean;
  isInternational?: boolean;
}

export const PaymentOptionsBlock: React.FC<PaymentOptionsBlockProps> = ({
  onChange,
  value,
  totalAmount,
  currency,
  creditLimit,
  radioGroupRef,
  radioGroupWidth,
  shouldRenderFlexPay,
  isInternational,
}) => {
  const { t } = useTranslation();

  return (
    <RadioGroup
      onChange={onChange}
      value={value}
      ref={radioGroupRef}
      w={['100%', '100%', '100%', '60%']}
    >
      <VStack spacing={4} align='stretch'>
        <Box p={4} border='1px' borderRadius='md' borderColor='gray.200'>
          <Radio
            value='bankTransfer'
            alignSelf='flex-start'
            width={{ xl: '100%', md: '100%' }}
          >
            <Flex direction='column'>
              <Flex justifyContent='space-between' alignItems='center'>
                <Text fontWeight='bold'>
                  {isInternational ? 'Pay now' : t('pay_now')}
                </Text>
                <Text>
                  {currency} {formatCurrency(totalAmount)}
                </Text>
              </Flex>
              <Divider mb={2} style={{ width: radioGroupWidth - 56 }} />
              <Text fontSize='sm'>
                {isInternational
                  ? 'Pay the total amount of the order now'
                  : t('pay_the_total_amount_of_order')}
              </Text>
            </Flex>
          </Radio>
        </Box>
        {shouldRenderFlexPay && (
          <Box p={4} border='1px' borderRadius='md' borderColor='gray.200'>
            <Radio value='flexPay' alignSelf='flex-start'>
              <Flex direction='column'>
                <Text fontWeight='bold'>
                  {isInternational ? 'Flex pay' : t('flex_pay')}
                </Text>
                <Text fontSize='sm'>
                  {isInternational
                    ? 'Available credit limit:'
                    : t('available_credit_limit')}{' '}
                  {currency} {formatCurrency(creditLimit)}
                </Text>
                <Divider mb={2} style={{ width: radioGroupWidth - 56 }} />
                <Text fontSize='sm'>
                  {isInternational
                    ? 'We pay your supplier now, you pay us later'
                    : t('we_pay_your_supplier')}
                </Text>
              </Flex>
            </Radio>
          </Box>
        )}
        {creditLimit === 0 && (
          <Box p={4} border='1px' borderRadius='md' borderColor='gray.200'>
            <Radio
              value='flexPay'
              isDisabled
              _disabled={{ opacity: 1, cursor: 'not-allowed' }}
              alignSelf='flex-start'
            >
              <Flex direction='column' maxW='500px'>
                <Text fontWeight='bold'>
                  {isInternational ? 'Flex pay' : t('flex_pay')}
                </Text>
                <Text fontSize='sm'>
                  {isInternational
                    ? 'Available credit limit:'
                    : t('available_credit_limit')}{' '}
                  {currency} {formatCurrency(creditLimit)}
                </Text>
                <Divider mb={2} style={{ width: radioGroupWidth - 56 }} />
                <Text fontSize='sm'>
                  {isInternational
                    ? 'Not eligible for Flex pay. Keep transacting to automatically qualify.'
                    : t('not_eligible_for_flex_pay')}
                </Text>
              </Flex>
            </Radio>
          </Box>
        )}
      </VStack>
    </RadioGroup>
  );
};
