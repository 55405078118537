import { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  Flex,
  Input,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  ModalFooter,
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react'
import { getFileLink } from 'services/file';

interface SelectedInvoiceEditModalProps {
  accessToken: string;
  selectedInvoice: any;
  onClose: () => void;
  saveInvoice: {
    save: (selectedInvoice: any, rows: any) => Promise<void>;
    isSaving: boolean;
  };
}

const SelectedInvoiceEditModal = (props: SelectedInvoiceEditModalProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const [resourceUrl, setResourceUrl] = useState<string | null>();
  const [rows, setRows] = useState([]);
  const [item, setItem] = useState('');
  const [quantity, setQuantity] = useState(0);
  const [cost, setCost] = useState(0);
  const toast = useToast()

  useEffect(() => {
    getFileLink(props.selectedInvoice.key, props.accessToken)
      .then((response) => {
        setResourceUrl(response);
      })
      .catch((error) => {
        console.log('error getting file link:', error);
      });
  }, [props.selectedInvoice.key, props.accessToken]);

  const handleAddRow = () => {
    if (item.trim().length === 0 || quantity <= 0) {
      return;
    }
    const newRow = { item, quantity, cost, total: quantity * cost };
    setRows([...rows, newRow]);
    setItem('');
    setQuantity(0);
    setCost(0);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    props.onClose();
  };

  const handleSaveChanges = async () => {
    await props.saveInvoice.save(props.selectedInvoice, rows);
    toast({
      description: 'Invoice created successfully',
      title: 'Invoice Created',
      status: 'success',
      duration: 20000,
      isClosable: true
    });
  };

  const disabled = props.saveInvoice.isSaving;
  const total = rows.reduce((acc, row) => acc + row.total, 0);

  return (
    <>
      {/* <Button onClick={handleOpenModal}>Open Modal</Button> */}

      <Modal isOpen={isOpen} onClose={handleCloseModal} size={'4xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Document</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex>
              <Box flex='1'>
                {/* Render document (image or PDF) */}
                <Box display={'flex'} flexDir={'row'} gap={'1rem'}>
                  {/* <Box width={'fit-content'}>Document Preview</Box> */}
                  <Box width={'fit-content'}>
                    <a
                      href={resourceUrl}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Open in New Tab
                    </a>
                  </Box>
                </Box>
                {props.selectedInvoice.mimeType === 'application/pdf' ? (
                  <iframe
                    src={resourceUrl}
                    width='100%'
                    height='500px'
                    title='document preview'
                  />
                ) : (
                  <img
                    src={resourceUrl}
                    alt='Document Preview'
                    style={{ maxWidth: '100%', maxHeight: '500px' }}
                  />
                )}
              </Box>
              <Box flex='1' ml={0} px={'1rem'}>
                <Flex mb={4}>
                  <Input
                    placeholder='Item'
                    value={item}
                    onChange={(e) => setItem(e.target.value)}
                    mr={2}
                  />
                  <Input
                    placeholder='Quantity'
                    type='number'
                    value={quantity}
                    onChange={(e) => setQuantity(parseInt(e.target.value))}
                    mr={2}
                  />
                  <Input
                    placeholder='Cost'
                    type='number'
                    value={cost}
                    onChange={(e) => setCost(parseFloat(e.target.value))}
                    mr={2}
                  />
                  <Button onClick={handleAddRow} px={'2rem'}>
                    Add Row
                  </Button>
                </Flex>
                <Table>
                  <Thead>
                    <Tr>
                      <Th>Item</Th>
                      <Th>Quantity</Th>
                      <Th>Cost</Th>
                      <Th>Total</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {rows.map((row, index) => (
                      <Tr key={index}>
                        <Td>{row.item}</Td>
                        <Td>{row.quantity}</Td>
                        <Td>{row.cost}</Td>
                        <Td>{row.total}</Td>
                      </Tr>
                    ))}
                    <Tr>
                      <Td />
                      <Td />
                      <Td />
                      <Td>{total} ZAR</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </Box>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme='blue'
              mr={3}
              onClick={handleCloseModal}
              disabled={disabled}
            >
              Close
            </Button>
            <Button
              variant='ghost'
              onClick={handleSaveChanges}
              disabled={disabled}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SelectedInvoiceEditModal;
