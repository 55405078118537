import { saveAs } from 'file-saver';

import { callGet, callPost, callUpdate } from '../../lib/api-private';
import { GetPaymentRequestsResponse } from '../../generated-client/model/get-payment-requests-response';
import { CreatePaymentRequestRequest } from '../../generated-client/model/create-payment-request-request';
import { GetAllPaymentRequestsResponse } from '../../generated-client/model/get-all-payment-requests-response';
import { UpdatePaymentRequestStatusRequest } from '../../generated-client/model/update-payment-request-status-request';
import { CreatePaymentRequestResponse } from '../../generated-client/model/create-payment-request-response';
import { GetPaymentRequestResponse } from '../../generated-client/model/get-payment-request-response';
import { UpdatePaymentRequestInstalmentStatusRequest } from '../../generated-client/model/update-payment-request-instalment-status-request';
import { GetAllPaymentRequestsRequest } from '../../generated-client/model/get-all-payment-requests-request';
import { AttachProofRequest } from '../../generated-client/model/attach-proof-request';
import { RepaymentTermsCalculateRequestBody } from '../../generated-client/model/repayment-terms-calculate-request-body';
import { RepaymentTermsCalculateResponse } from '../../generated-client/model/repayment-terms-calculate-response';

export const getPaymentRequests = async (
  accessToken: string
): Promise<GetPaymentRequestsResponse> => {
  const data = await callGet(accessToken, '/paymentRequest');
  return data;
};

export const getPaymentRequest = async (
  accessToken: string,
  id: string
): Promise<GetPaymentRequestResponse> => {
  const data = await callGet(accessToken, '/paymentRequest/' + id);
  return data;
};

export const attachProofOfUserPayment = async (
  accessToken: string,
  data: AttachProofRequest
): Promise<void> => {
  callUpdate(accessToken, '/paymentRequest/proofOfUserPayment', data);
};

export const createPaymentRequest = async (
  accessToken: string,
  data: CreatePaymentRequestRequest
): Promise<CreatePaymentRequestResponse> => {
  const result = callPost(accessToken, '/paymentRequest', data);
  return result;
};

export const getAllPaymentRequestsAdmin = async (
  accessToken: string,
  data: GetAllPaymentRequestsRequest
): Promise<GetAllPaymentRequestsResponse> => {
  const result = await callPost(accessToken, '/paymentRequest/all', data);
  return result;
};

export const validatePurchaseOrder = async (id: string): Promise<Boolean> => {
  const result = await callGet(
    '',
    `/paymentRequest/purchaseOrder/${id}/validate/`
  );
  return result;
};

export const attachInvoiceToPaymentRequest = async (
  id: string,
  fileId: string,
  companyId: string
) => {
  const result = await callPost(
    null,
    `/paymentRequest/purchaseOrder/${id}/invoice`,
    {
      fileId,
      companyId,
    }
  );
};

export const updatePaymentRequestAdmin = async (
  accessToken: string,
  data: UpdatePaymentRequestStatusRequest
): Promise<void> => {
  return await callUpdate(accessToken, '/paymentRequest', data);
};

export const updatePaymentRequestInstalmentAdmin = async (
  accessToken: string,
  data: UpdatePaymentRequestInstalmentStatusRequest
): Promise<void> => {
  callUpdate(accessToken, '/paymentRequest/instalment', data);
};

export const downloadPurchaseOrder = async (
  id: string,
  accessToken: string
) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_BACKEND_URL + '/paymentRequest/purchaseOrder/' + id,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const blob = await response.blob();

    const contentDisposition = response.headers.get('Content-Disposition');

    const filename = contentDisposition
      ? contentDisposition.split('filename=')[1]
      : 'file.pdf';
    saveAs(blob, filename);
  } catch (error) {
    console.error('There has been a problem with your fetch operation:', error);
  }
};

export const fetchRepaymentTerms = async (
  data: RepaymentTermsCalculateRequestBody,
  accessToken: string
): Promise<RepaymentTermsCalculateResponse> => {
  return callPost(
    accessToken,
    '/paymentRequest/repaymentTerms/calculate',
    data
  );
};

export const getUnAppliedLateFees = async (
  accessToken: string
): Promise<GetPaymentRequestsResponse> => {
  const data = await callGet(accessToken, '/paymentRequest/lateFees');
  return data;
};
