import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';

const PendingInvoicesTable = ({
  pendingInvoices,
  handleSelectedInvoice,
}: {
  pendingInvoices: Array<any>;
  handleSelectedInvoice: (invoice: any) => void;
}) => {
  console.log('pending invoices', pendingInvoices);
  return (
    <Table>
      <Thead>
        <Tr>
          <Th>Invoice ID</Th>
          <Th>Purchase Order</Th>
          <Th>Company Name</Th>
          <Th>Date</Th>
        </Tr>
      </Thead>
      <Tbody>
        {pendingInvoices.map((invoice) => (
          <Tr key={invoice.id} onClick={() => handleSelectedInvoice(invoice)}>
            <Td>{invoice.id}</Td>
            <Td>{invoice.paymentRequestId}</Td>
            <Td>{invoice.company.name}</Td>
            <Td>{invoice.date}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default PendingInvoicesTable;
