import { callGet, callPost } from 'lib/api-private';

export const getAllUnprocessedInvoices = async (accessToken: string) => {
  const response = await callGet(accessToken, '/invoices/unprocessed');
  return response;
};

export const createInvoice = async (
  accessToken: string,
  selectedInvoice: any,
  rows: any
) => {
  const response = await callPost(accessToken, '/invoices', {
    selectedInvoice,
    rows,
  });
  return response;
};
