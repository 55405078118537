import { useEffect, useState } from 'react';
import PendingInvoicesTable from './components/PendingInvoicesTable';
import { useGetPendingInvoices, useHandleSaveInvoice } from './hooks';
import SelectedInvoiceEditModal from './components/SelectedInvoiceEditModal';

export const PendingInvoices = () => {
  const { accessToken, pendingInvoices, reload, isLoading, error } =
    useGetPendingInvoices();
  const saveInvoice = useHandleSaveInvoice();
  const [selectedInvoice, setSelectedInvoice] = useState<any>(null);
  const handleSelectedInvoice = (invoice: any) => {
    console.log('handling invoice', invoice);
    setSelectedInvoice(invoice);
  };

  useEffect(() => {
    if (saveInvoice.success) {
      console.log(
        'saving invoice success',
        // saveInvoice.isSaving,
        saveInvoice.success
      );
      setSelectedInvoice(null);
      console.log('setting invoice to null');
      saveInvoice.reset();
      reload();
      console.log('reloading');
    }
  }, [saveInvoice.success]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <h1>Pending Invoices</h1>
      {!selectedInvoice && (
        <PendingInvoicesTable
          pendingInvoices={pendingInvoices}
          handleSelectedInvoice={handleSelectedInvoice}
        />
      )}
      {selectedInvoice && (
        <>
          <SelectedInvoiceEditModal
            accessToken={accessToken}
            selectedInvoice={selectedInvoice}
            saveInvoice={saveInvoice}
            onClose={() => setSelectedInvoice(undefined)}
          />
        </>
      )}
    </div>
  );
};
export default PendingInvoices;
